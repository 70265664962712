import React, { Component } from 'react';
import { Typography, Grid, Table, TableHead, TableCell, TableRow, TableBody, Chip } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button, FormControl, Card } from 'react-bootstrap';
import { PostData, GetData, DeleteData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import FileUpload from '../AddNew/FileUpload';
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import history from '../../history';

class FacultyPhd extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email:this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    title_p:'',
    year_p:'',
    university_p:'',
    department_p:'',
    supervisor_p:'',
    phd:[],
    title_m1:'',
    year_m1:'',
    university_m1:'',
    department_m1:'',
    supervisor_m1:'',
    mphil:[],
    year_m1:'',
    course_m1:'',
    subject_m1:'',
    university_m1:'',
    mDegree: [],
    year_b:'',
    course_b:'',
    subject_b:'',
    university_b:'',
    bDegree: [],
    description_o: '',
    year_o: '',
    oDegree: []

  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`)
    .then((resp)=>{
        if(resp){


            let phd = resp.phd != null ? resp.phd : []
            let mphil = resp.mphil != null ? resp.mphil : []
            let mDegree = resp.mDegree != null ? resp.mDegree : []
            let bDegree = resp.bDegree != null ? resp.bDegree : []
            let oDegree = resp.oDegree != null ? resp.oDegree : []

            this.setState({
                ...resp,
                ...phd[0],
                ...mphil[0],
                ...mDegree[0],
                ...bDegree[0],
                oDegree,
                
              });
        }
        this.setState({
          isLoaded: true
        })
    })

    
  }

//   componentDidUpdate(prevProps){
//     if(this.props !== prevProps){
//         this.setState({
//             seminar:this.props.seminar
      
//         })
//     }
// }


  onSubmit = (e) => {
    e.preventDefault();

    let d = {
        title_p:this.state.title_p,
        year_p:this.state.year_p,
        university_p:this.state.university_p,
        department_p:this.state.department_p,
        supervisor_p:this.state.supervisor_p
    }
    let phd = [];
    phd.push(d);

    let f = {
        title_m1:this.state.title_m1,
        year_m1:this.state.year_m1,
        university_m1:this.state.university_m1,
        department_m1:this.state.department_m1,
        supervisor_m1:this.state.supervisor_m1
    }
    let mphil = [];
    mphil.push(f);


    let g = {
      year_m:this.state.year_m,
      course_m:this.state.course_m,
      subject_m:this.state.subject_m,
      university_m:this.state.university_m,
  }
  let mDegree = [];
  mDegree.push(g);


  let h = {
    year_b:this.state.year_b,
    course_b:this.state.course_b,
    subject_b:this.state.subject_b,
    university_b:this.state.university_b,
}
let bDegree = [];
bDegree.push(h);






    
    

    this.setState({
        phd,
        mphil,
        mDegree,
        bDegree,

    },()=>{


        let data = {
            email:this.state.email,
            employee_id:this.state.employee_id,
            phd:this.state.phd,
            mphil:this.state.mphil,
            mDegree:this.state.mDegree,
            bDegree:this.state.bDegree,
            oDegree:this.state.oDegree,
            m_type:this.state.m_type
        }
  

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, data).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
    
})
  };



  onAdd = () => {
    
    let oDegree = this.state.oDegree != null ? this.state.oDegree : [];

    if(this.state.description_o != "" && this.state.year_o != ""){
      oDegree.push({
        description_o:this.state.description_o,
        year_o:this.state.year_o
      })

      this.setState({
        oDegree: oDegree,
        description_o: '',
        year_o: ''
      })
    }
  }



  onChange1 = (name, index, value) => {
    let oDegree = this.state.oDegree;

    oDegree[index][name] = value;
    this.setState({
      oDegree
    })
  }


  onDelete = (index) => {
    let oDegree = this.state.oDegree;

    oDegree.splice(index,1)
    this.setState({
      oDegree
    })

  }



  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

       {this.state.isLoaded && <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.title}
          </Typography>

          <Card>
            <Card.Body>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Degree Details</label>
                 
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Course</label>
                  <FormControl
                    as="select"
                    name="course_b"
                    
                    onChange={this.onChange}
                    value={this.state.course_b}
                    placeholder="Select Course"
                  >
                    <option>Select</option>
                    <option value="BA">BA</option>
                    <option value="BSC">BSC</option>
                    <option value="BCOM">BCOM</option>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <label>Subject</label>
                  <FormControl
                    as="input"
                    name="subject_b"
                    onChange={this.onChange}
                    value={this.state.subject_b}
                    placeholder="Subject"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>University/Institution</label>
                  <FormControl
                    as="input"
                    name="university_b"
                    onChange={this.onChange}
                    value={this.state.university_b}
                    placeholder="University/Institute"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Year</label>
                  <FormControl
                    as="input"
                    name="year_b"
                    onChange={this.onChange}
                    value={this.state.year_b}
                    placeholder="Year"
                  />
                </Grid>
                
               
               
                
              </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Master Degree Details</label>
                 
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Course</label>
                  <FormControl
                    as="select"
                    name="course_m"
                    
                    onChange={this.onChange}
                    value={this.state.course_m}
                    placeholder="Select Course"
                  >
                    <option>Select</option>
                    <option value="MA">MA</option>
                    <option value="MSC">MSC</option>
                    <option value="MCOM">MCOM</option>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <label>Subject</label>
                  <FormControl
                    as="input"
                    name="subject_m"
                    onChange={this.onChange}
                    value={this.state.subject_m}
                    placeholder="Subject"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>University/Institution</label>
                  <FormControl
                    as="input"
                    name="university_m"
                    onChange={this.onChange}
                    value={this.state.university_m}
                    placeholder="University/Institute"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Year</label>
                  <FormControl
                    as="input"
                    name="year_m"
                    onChange={this.onChange}
                    value={this.state.year_m}
                    placeholder="Year"
                  />
                </Grid>
                
               
               
                
              </Grid>
            <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>PhD Details</label>
                 
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Title of the PhD Research:</label>
                  <FormControl
                    as="input"
                    name="title_p"
                    
                    onChange={this.onChange}
                    value={this.state.title_p}
                    placeholder="Title of the PhD Research"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <label>Year of Receiving Award</label>
                  <FormControl
                    as="input"
                    name="year_p"
                    onChange={this.onChange}
                    value={this.state.year_p}
                    placeholder="Year of Receiving Award"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>University:</label>
                  <FormControl
                    as="input"
                    name="university_p"
                    onChange={this.onChange}
                    value={this.state.university_p}
                    placeholder="University"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Department:</label>
                  <FormControl
                    as="input"
                    name="department_p"
                    onChange={this.onChange}
                    value={this.state.department_p}
                    placeholder="Department"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Name of the Supervisor:</label>
                  <FormControl
                    as="input"
                    name="supervisor_p"
                    onChange={this.onChange}
                    value={this.state.supervisor_p}
                    placeholder="Name of the Supervisor"
                  />
                </Grid>
               
               
                
              </Grid>
            <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>M. Phil Details</label>
                 
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Title of the M. Phil Research:</label>
                  <FormControl
                    as="input"
                    name="title_m1"
                    
                    onChange={this.onChange}
                    value={this.state.title_m1}
                    placeholder="Title"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <label>Year of Receiving M. Phil Degree:</label>
                  <FormControl
                    as="input"
                    name="year_m1"
                    onChange={this.onChange}
                    value={this.state.year_m1}
                    placeholder="Year of Receiving M. Phil Degree"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>University:</label>
                  <FormControl
                    as="input"
                    name="university_m1"
                    onChange={this.onChange}
                    value={this.state.university_m1}
                    placeholder="University"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Department:</label>
                  <FormControl
                    as="input"
                    name="department_m1"
                    onChange={this.onChange}
                    value={this.state.department_m1}
                    placeholder="Department"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Name of the Supervisor:</label>
                  <FormControl
                    as="input"
                    name="supervisor_m1"
                    onChange={this.onChange}
                    value={this.state.supervisor_m1}
                    placeholder="Name of the Supervisor"
                  />
                </Grid>
               
               
                
              </Grid>

              <br />

              <Grid container spacing={2} style={{backgroundColor: '#f9f9f9'}}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Other Details</label>
                 
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Description</label>
                  <FormControl
                    as="input"
                    name="description_o"
                    
                    onChange={this.onChange}
                    value={this.state.description_o}
                    placeholder="Description"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <label>Year</label>
                  <FormControl
                    as="input"
                    name="year_o"
                    onChange={this.onChange}
                    value={this.state.year_o}
                    placeholder="Year"
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  
                  <Button style={{marginTop: 25}} onClick={() => this.onAdd()}>Add</Button>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <table width="100%" className="table table-sm table-striped">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>Description</td>
                          <td>Year</td>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(this.state.oDegree) && this.state.oDegree.map((el,index) => <tr key={index}>
                            <td>{index+1}</td>
                            <td>
                            <FormControl
                              as="input"
                              name="description_o"
                              
                              onChange={e => this.onChange1('description_o', index, e.target.value)}
                              value={el.description_o}
                              placeholder="Description"
                            />
                            </td>
                            <td>
                            <FormControl
                              as="input"
                              name="year_o"
                              
                              onChange={e => this.onChange1('year_o', index, e.target.value)}
                              value={el.year_o}
                              placeholder="year"
                            />
                            </td>
                            <td>
                              <a style={{cursor: 'pointer', color: 'blue'}} onClick={() => this.onDelete(index)}>Delete</a>
                            </td>
                          </tr>)}
                      </tbody>
                  </table>
                </Grid>
               
               
                
               
               
                
              </Grid>

              <div align="right">
                <Button size="sm" variant="info" type="submit">
                  Submit
                </Button>
              </div>
            </Card.Body>
          </Card>
        </form>}
      </div>
    );
  }
}

export default FacultyPhd;
